/**
 *
 *     Licensed under the Apache License, Version 2.0 (the "License");
 *     you may not use this file except in compliance with the License.
 *     You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 *     Unless required by applicable law or agreed to in writing, software
 *     distributed under the License is distributed on an "AS IS" BASIS,
 *     WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *     See the License for the specific language governing permissions and
 *     limitations under the License.
 *
 */
// import * as THREE from 'three';
import ShapesFactory from '../ShapesFactory';
import ShapeParent from './ShapeParent';

class ShapeDefault extends ShapeParent {
// No operations as ShapeParent.getInnerCircleGeometry and ShapeParent.getOuterBorderGeometry
// generate the default circle node border and center fill.
// aSqrd-eSqrd, 21-May-2019
}
ShapesFactory.registerShape('default', ShapeDefault);
export default ShapeDefault;
